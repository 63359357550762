<template>
  <Authenticated>
    <Warning
      :show="showDeleteConfirm"
      message="Confirm you want to delete this category?"
      primary="Delete"
      @save="removeCategory"
    />
    <ProductModal
      :show="productModalShow"
      :product="currentProduct"
      :modifierSets="modifier_sets"
      @save="saveProduct"
      @remove="removeProduct"
      @clone="cloneProduct"
    />
    <div v-if="current">
      <div class="action-buttons">
        <button @click="showProduct">
          <font-awesome-icon icon="plus" /> Add Product
        </button>
        <button class="danger" @click="confirmDelete">
          <font-awesome-icon icon="trash" /> Delete Category
        </button>
      </div>

      <div class="actions">
      <h2 class="title">{{ name }}</h2></div>
      <div class="description">
        <div class="preview">
          <h3>Description:</h3>
          <div class="layout">
            <p class="desc" v-html="current.description"></p>
            <font-awesome-icon
              icon="pencil"
              :class="editorShow ? 'icon-active' : 'icon'"
              @click="this.editorShow = !this.editorShow"
            />
          </div>
        </div>
        <div :className="editorShow ? 'editor-show' : 'editor-hide'" id="center">
          <textarea type="text" rows="6" class="radius" v-model="current.description"></textarea>
          <button class="action primary" @click.prevent.stop="save">
            Save
          </button>
        </div>
      </div>
      <Toggle
        class="toggle"
        onLabel="POS Only"
        offLabel="POS/WEB"
        v-model="current.posOnly"
      />
      <Toggle
        class="toggle"
        onLabel="Edit"
        offLabel="Re Order"
        v-model="reOrder"
      />
      <draggable
        v-if="reOrder"
        :list="products"
        @start="drag = true"
        @end="checkMove"
        tag="ul"
        class="grid wrapper content-gap"
        item-key="element"
      >
        <template #item="{ element }">
          <li class="modifierBox">
            <div class="box">
              <p style="text-align: center">
                <span class="modifier-name">{{
                  getProduct(element).name
                }}</span>
                <span class="modifier-price">{{
                  "$" +
                  (getProduct(element)
                    ? (+getProduct(element).price).toFixed(2)
                    : "0.00")
                }}</span>
              </p>
            </div>
          </li>
        </template>
      </draggable>
      <ul
        v-if="!reOrder"
        :class="(tableMode ? 'table' : 'grid') + ' wrapper content-gap'"
      >
        <li
          @click="showProduct(product)"
          v-bind:key="idx"
          v-for="(product, idx) in products"
          class="modifierBox"
        >
          <div class="box" v-if="getProduct(product)">
            <p style="text-align: center">
              <span class="modifier-name">{{ getProduct(product).name }}</span>
              <span class="modifier-price">{{
                "$" +
                (getProduct(product)
                  ? (+getProduct(product).price).toFixed(2)
                  : "0.00")
              }}</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "@/components/_layouts/Authenticated";
import Warning from "@/components/_layouts/Warning";
import { mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";
import Toggle from "@vueform/toggle";
import ProductModal from "@/components/Categories/ProductModal";
import draggable from "vuedraggable";
import Slug from "slug";

export default {
  name: "Category",
  mixins: [createdMixin],
  components: {
    Authenticated,
    Warning,
    Toggle,
    ProductModal,
    draggable,
  },

  data() {
    return {
      channel: null,
      currentProduct: null,
      currentDrag: null,
      currentDragOverCategory: null,
      showDeleteConfirm: false,
      inventory: false,
      required: false,
      oneonly: false,
      loaded: false,
      showProductModal: false,
      tableMode: false,
      editorShow: false,
      drag: false,
      reOrder: false,
      productRoute: false,
      posOnly: false
    };
  },

  methods: {
    checkMove: function () {
      this.drag = false;
      //this.products.splice(e.draggedContext.index, 1);
      //this.products.splice(e.draggedContext.futureIndex, 0, e.draggedContext.element);

      this.channel.push("document:update", this.current);
      ////console.log(this.products);
      ////console.log(this.current);
    },
    reOrderProducts() {
      this.reOrder = !this.reOrder;
    },
    confirmDelete() {
      this.showDeleteConfirm = !this.showDeleteConfirm;
    },
    save() {
      this.channel.push("document:update", this.current);
      //console.log(this.current);
    },
    newItem() {
      this.channel.push("document:new", this.current);
      //console.log(this.current);
    },
    getProduct(name) {
      var foundProduct = this.allProducts.find((p) => {
        return p.id === "product:" + name;
      });
      return foundProduct ? foundProduct.value : null;
    },
    updateProduct(name) {
      this.currentProduct = this.getProduct(name);
    },
    getBackground(name) {
      var foundProduct = this.allProducts.find((p) => {
        return p.id === "product:" + name;
      });
      return foundProduct && foundProduct.value.image
        ? "color: var(--primary-colour); background-size: contains; background-image: url(" +
            foundProduct.value.image +
            ");"
        : "";
    },
    showProduct(product_slug) {
      var foundProduct = this.allProducts.find((p) => {
        return p.id === "product:" + product_slug;
      });

      if (foundProduct) {
        if (!foundProduct.value.soldout) {
          foundProduct.value.soldout = false;
        }
        this.currentProduct = foundProduct.value;
        ////console.log(foundProduct);
      } else {
        this.currentProduct = {
          name: null,
          description: null,
          caption: null,
          active: true,
          price: null,
          modifiersets: [],
          images: [],
          soldout: false,
        };
      }
      this.showProductModal = !this.showProductModal;
      ////console.log(this.showProductModal);
    },
    cloneProduct(product) {
      this.currentProduct = product;
      this.currentProduct._id = null;
      this.currentProduct._rev = null;
      this.currentProduct.name = null;
      this.showProductModal = !this.showProductModal;
    },
    saveProduct(product) {
      var foundProduct = this.allProducts.find((p) => {
        return p.id === "product:" + Slug(product.name);
      });
      ////console.log(product.name);
      if (foundProduct) {
        console.log(product);
        foundProduct = product;
        this.channel.push("document:update", product);
      } else {
        product._id = "product:" + Slug(product.name);
        product.category = Slug(this.name);
        this.channel.push("document:new", product);
        ////console.log(product);
        this.current.products.push(Slug(product.name));
        this.save();
      }
      this.$notify({
          title: "Product has been saved",
          type: "success",
        });
    },
    async removeProduct(product) {
      var foundProductIndex = this.current.products.findIndex((p) => {
        return "product:" + p === product._id;
      });

      if (foundProductIndex != -1) {
        await this.channel.push("document:delete", {
          doc: product._id,
          rev: product._rev,
          category: Slug(this.name),
        });
        this.current.products.splice(foundProductIndex, 1);
        this.save();
      }
    },
    async removeCategory() {
      console.log(this.current);
      /* step two remove it from modifier_sets */
      await this.channel.push("document:delete", {
        doc: this.current._id,
        rev: this.current._rev,
      });
      this.$router.push("/products");
    },
  },

  watch: {
    "current.active": {
      handler: function (newValue, oldValue) {
        if (oldValue != null) this.save();
      },
    },
    posOnly: {
      handler: function() {
        //console.log(this.posOnly);
        this.current.posOnly = this.posOnly;
        this.save();
      }
    },
  },

  mounted() {
    if (this.$route.params.product) {
      this.productRoute = true;
    }
    this.$store.commit("currPage", "products");
  },
  computed: {
    productModalShow() {
      if (this.productRoute) {
        if (this.getProduct(this.$route.params.product)) {
          this.updateProduct(this.$route.params.product);
          return true;
        } else {
          return this.showProductModal;
        }
      } else {
        return this.showProductModal;
      }
    },
    product() {
      this.getProduct("test");
      //console.log(this.$route.params.product);
      return "test";
    },
    slug() {
      return this.$route.params.category;
    },
    fullslug() {
      return "category:" + this.$route.params.category;
    },
    name() {
      return this.$route.params.category.replace(/-/g, " ");
    },
    current() {
      let foundCategory = this.categories.find(
        (category) => category.id === "category:" + this.slug
      );
      return foundCategory ? foundCategory.value : null;
    },
    products() {
      return this.current ? this.current.products : null;
    },
    ...mapGetters({
      categories: "allCategories",
      modifier_sets: "modifier_sets",
      allProducts: "allProducts",
    }),
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>

<style scoped lang="scss">
@import "public/layout";
@import "public/actions";
@import "public/wrapper";
@import "public/toggle";

  .radius {
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    width: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

</style>
